<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-title>Module</v-list-item-title>
              <v-list-item-subtitle>{{ module }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sub-Module</v-list-item-title>
              <v-list-item-subtitle>{{ submodule }}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>ID</v-list-item-title>
              <v-list-item-subtitle>{{ id }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn to="/test/other">Bad Link</v-btn>
            <v-btn to="/test/page/79">Good Link</v-btn>
            <v-btn to="/test/page/94">Good Link 2</v-btn>
            <v-btn to="/test/page/7">Good Link 3</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const module = computed(() => root.$route.params.module)
    const submodule = computed(() => root.$route.params.submodule)
    const id = computed(() => root.$route.params.id)

    return {
      module,
      submodule,
      id
    }
  }
}
</script>
